import { NextRouter } from 'next/router';
import { setHowItWorksModalOpen } from '@/hooks/useModalRouterParams';
import { trackEvent } from '@/utils/analytics';

export const onHowItWorksModalOpen = (router: NextRouter) => (): void => {
  trackEvent('How it Works Clicked');
  setHowItWorksModalOpen(router, true);
};

export const onHowItWorksModalClose = (router: NextRouter) => (): void => {
  setHowItWorksModalOpen(router, false);
};
