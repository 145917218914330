import { Box, Flex, HStack, Tabs, useTheme } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import Divider from './Divider';
import Heading from '@/components/shared/Heading';
import Tab from '@/components/shared/Tab';
import Tag from '@/components/shared/Tag';
import Text from '@/components/shared/Text';
import { useRouter } from 'next/router';

interface SubnavItem {
  comingSoon?: boolean;
  href: string;
  label: string;
}

interface Props {
  button?: ReactNode;
  description?: string;
  subnav?: SubnavItem[];
  title: string;
}

const PageHeader: React.FC<Props> = ({ button, description, subnav, title }: Props) => {
  const theme = useTheme();
  const router = useRouter();

  const selectedIndex = subnav?.findIndex((item) => item.href == router.pathname);

  return (
    <>
      <Box maxW={theme.maxPageWidth} width="100%" px={{ base: 4, md: 6, xl: 8 }} mb={subnav ? 0 : '2.6em'}>
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          justify="space-between"
          mt="42px"
          alignItems={{ base: 'flex-start', md: 'center' }}
        >
          <Box mr={10}>
            <Heading fontSize="3xl">{title}</Heading>
            {description && (
              <Text color="gray.500" fontWeight="400" mt={2} mr={10}>
                {description}
              </Text>
            )}
          </Box>

          <Box display={{ base: 'none', md: 'block' }}>{button}</Box>
        </Flex>

        {subnav && (
          <Tabs index={selectedIndex} mt="2.2em">
            <HStack spacing="2.5em">
              {subnav.map(({ href, label, comingSoon }, index) => {
                return (
                  <Flex key={index} alignItems="center">
                    <Tab mb="0px" px="0px" fontWeight="600" onClick={() => router.push(href)} borderColor="transparent">
                      {label}
                    </Tab>
                    {comingSoon && (
                      <Box ml={4}>
                        <Tag mb="3px">Coming Soon</Tag>
                      </Box>
                    )}
                  </Flex>
                );
              })}
            </HStack>
          </Tabs>
        )}
      </Box>

      <Divider mb={8} />
    </>
  );
};

export default PageHeader;
