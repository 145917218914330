import { Fade, Icon, Tag, TagLabel } from '@chakra-ui/react';

import { BiCheck } from 'react-icons/bi';
import { GoAlert } from 'react-icons/go';
import React from 'react';
import Spinner from './Spinner';
import { motion } from 'framer-motion';
import { useWeb3React } from '@web3-react/core';

interface Props {
  errorLoading: boolean;
  isLoading: boolean;
}

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const TheGraphLoadingTag: React.FC<Props> = ({ errorLoading, isLoading }: Props) => {
  const { account } = useWeb3React();

  if (account == null) {
    return null;
  }

  return (
    <motion.div
      variants={variants}
      initial="hidden"
      animate={isLoading ? 'visible' : 'hidden'}
      transition={{ delay: 2, duration: 0.3 }}
    >
      <Tag
        minW="250px"
        position="fixed"
        bottom="110px"
        left="50%"
        zIndex="tooltip"
        backgroundColor="gray.700"
        variant="solid"
        transform="translateX(-50%)"
        borderRadius="full"
        size="lg"
        justifyContent="center"
        alignItems="center"
      >
        <Fade in={!isLoading}>
          <Icon display={isLoading ? 'none' : 'block'} as={BiCheck} boxSize={6} mb="2px" />
        </Fade>

        <Fade in={errorLoading}>
          <Icon display={!errorLoading ? 'none' : 'block'} as={GoAlert} boxSize={4} mb="1px" ml={2} />
        </Fade>

        {isLoading && !errorLoading && <Spinner size="xs" speed="0.9s" color="gray.300" />}

        <TagLabel fontSize="sm" fontWeight="600" my={4} ml={3} mr={2}>
          {chooseText(isLoading, errorLoading)}
        </TagLabel>
      </Tag>
    </motion.div>
  );
};

const chooseText = (isLoading: boolean, errorLoading: boolean) => {
  if (errorLoading) {
    return 'Trouble loading your data from The Graph.  Please try again later.';
  } else if (isLoading) {
    return 'Connecting to The Graph.';
  } else {
    return 'Connected to The Graph.';
  }
};

export default TheGraphLoadingTag;
