import { Account, Comptroller, Market, PositionTypes } from '@/types';

import React from 'react';
import dynamic from 'next/dynamic';
import { onHowItWorksModalClose } from '@/helpers/howItWorksModalHelpers';
import { onManageModalClose } from '@/helpers/manageModalHelpers';
import { useRouter } from 'next/router';

const HowItWorksModal = dynamic(() => import('@/components/modals/how_it_works_modal/HowItWorksModal'));
const SupplyModal = dynamic(() => import('./SupplyModal'));
const InvestModal = dynamic(() => import('./InvestModal'));
const BorrowModal = dynamic(() => import('./BorrowModal'));

interface Props {
  account: Account;
  comptroller: Comptroller;
  markets: Market[];
  positionType?: PositionTypes;
  protectedScreen?: boolean;
}

const CommonModals: React.FC<Props> = ({
  account,
  comptroller,
  markets,
  positionType,
  protectedScreen = false,
}: Props) => {
  const router = useRouter();

  const ManageModal = chooseManageModal(positionType);

  return (
    <>
      {positionType != null && (
        <ManageModal
          account={account}
          comptroller={comptroller}
          markets={markets}
          onClose={onManageModalClose(router)}
        />
      )}

      {protectedScreen && <HowItWorksModal onClose={onHowItWorksModalClose(router)} />}
    </>
  );
};

const chooseManageModal = (positionType: PositionTypes) => {
  if (positionType === PositionTypes.Supply) {
    return SupplyModal;
  } else if (positionType === PositionTypes.Borrow) {
    return BorrowModal;
  } else {
    return InvestModal;
  }
};

export default CommonModals;
