import { Box, BoxProps, Flex } from '@chakra-ui/react';

import Heading from '@/components/shared/Heading';
import React from 'react';
import Skeleton from './Skeleton';
import Text from '@/components/shared/Text';

interface Props extends BoxProps {
  color?: string;
  label: string;
  loading?: boolean;
  number: number | string;
  unit?: string;
}

const Stat: React.FC<Props> = ({ label, loading, number, unit, color, ...props }: Props) => {
  const numberColor = number == 0 ? 'gray.200' : color;

  return (
    <Box {...props}>
      <Text fontWeight="600" color="gray.500" fontSize="sm" mb={2}>
        {label}
      </Text>
      <Skeleton isLoaded={!loading} height="8" width="24">
        <Flex color={numberColor}>
          {unit === '$' && (
            <Heading fontWeight="600" fontSize="xl" mt="2px">
              $
            </Heading>
          )}
          <Heading fontWeight="800" fontSize={{ base: '2xl', md: '3xl' }}>
            {number}
          </Heading>
          {unit === '%' && (
            <Heading alignSelf="flex-end" fontWeight="600" fontSize="xl" mb="4px">
              %
            </Heading>
          )}
        </Flex>
      </Skeleton>
    </Box>
  );
};
export default Stat;
