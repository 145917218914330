import { Tag as ChakraTag, TagProps } from '@chakra-ui/react';

import React from 'react';

type Props = TagProps;

const Tag: React.FC<Props> = ({ children, ...props }: Props) => {
  return (
    <ChakraTag borderRadius="base" fontWeight="700" color="gray.500" backgroundColor="gray.100" {...props}>
      {children}
    </ChakraTag>
  );
};
export default Tag;
